<!-- XXXX签名 -->
<template>
  <SignCanvas
    ref="SignCanvasRef"
    :signName="nameList[nameIndex]"
    @sureHandler="sureSignHandler"
    v-if="showSignCanvas"
  />
  <div class="sign-container" v-else>
    <div class="sign-title">
      <van-button type="primary" @click="showSignCanvas = true"
        >开始签名</van-button
      >
    </div>
    <div class="sign-image" v-if="signFile">
      <img :src="signFile" alt="签名图片" style="width: 100%" />
    </div>
  </div>
</template>

<script>
import SignCanvas from '@/components/SignCanvas'
export default {
  name: 'BloodRegisterSign',
  components: {
    SignCanvas,
  },

  data() {
    return {
      // ...
      showSignCanvas: false, // 是否显示签名画布
      inputData: {}, // 该数据中 cxmjView 为需要签名的人员姓名
      nameIndex: 0, // 当前签名为第几个人签名
      signFile: null, // 签名图片文件
    }
  },

  computed: {
    nameList() {
      return this.inputData.cxmjView ? this.inputData.cxmjView.split(',') : [] // 需要有多个签名
    },
  },

  watch: {},

  created() {
    console.log('this.$route----', this.$route)
    this.inputData = JSON.parse(this.$route.query.inputData || '{}')
    // ...
  },

  methods: {
    sureSignHandler(data) {
      console.log({ data })
      this.signFile = data
      this.submitHandler()
      // if (this.nameIndex < this.nameList.length - 1) {
      //   this.nameIndex++
      //   this.$refs.SignCanvasRef.resetHandler()
      // } else {
      //   this.submitHandler()
      // }
    },
    submitHandler() {
      console.log('signFileList', this.signFile)
      this.showSignCanvas = false
      // TODO：调用接口，提交签名图片等数据
    },
  },
}
</script>

<style lang="less" scoped>
.sign-container {
  padding-top: 20px;
  background-color: #f5f5f5;
}
</style>
